
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.weui-cells__group_form {
  display: flex;
  flex-direction: column; /* 在小屏幕上垂直排列 */

  @media only screen and (min-width: 768px) {
    flex-direction: row; /* 在大屏幕上水平排列 */
  }
}

.weui-cell {
  width: 100%; /* 设置宽度为父容器的100% */

  @media only screen and (min-width: 768px) {
    width: 50%; /* 在大屏幕上设置宽度为父容器的50% */
  }
}

.header {
  height: 44px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
// 全页背景色
.main {
  height: 100vh;
  background-color: #f5f5f5;

  .all-container {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    font-size: 15px;
    background: #f4f4f4;
    margin-bottom: 59px;
    .header-container {
      margin-top: -44px;
      height: 200px;
      padding-left: 22px;
      padding-top: 64px;
      background-color: #2c3e50;
      background-size: cover;
      display: flex;
      align-items: center;
      img {
        width: 63px;
        height: 63px;
        border-radius: 35px;
        margin-right: 20px;
        border: 2px solid #fff;
      }
      span {
        font-size: 16px;
        color: #fff;
      }
    }
  }
  .order {
    display: flex;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: 13px;
    padding: 0;
    height: 79px;
    margin-bottom: 10px;
    background: #fff;
    align-items: center;
    .tips {
      flex: 1;
      color: #191a1b;
      font-size: 17px;
      div:nth-child(2) {
        font-size: 13px;
        color: #797d82;
      }
    }
    .money {
      flex: 1;
      color: #191a1b;
      font-size: 17px;
      div:nth-child(2) {
        font-size: 13px;
        color: #797d82;
      }
    }
  }
  .my-card,
  .system-set {
    width: 100%;
    height: 49px;
    padding: 0 15px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    img {
      width: 20px;
      height: 20px;
    }
    .label {
      margin-left: 15px;
      color: #191a1b;
      flex: 1;
    }
    .arrow {
      text-align: right;
      width: 6px;
      height: 10px;
    }
  }
  .my-card:after {
    content: " ";
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    border-bottom: 1px solid #ededed;
    color: #ededed;
    left: 13px;
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;