
.wisdom-camp-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 800px;
}

.responsive-image {
  max-width: 100%;
  height: auto;
  display: block;
}

.carousel-overlay {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  max-width: 400px;
  padding: 20px;
  border-radius: 10px;
}

.carousel-text {
  color: #fff;
  font-size: 38px;
  opacity: 0.9;
  text-align: center;
  margin: 0;
  font-family: "BoldFont", sans-serif;
  filter: brightness(1.2);
  -webkit-text-size-adjust: none;
  position: relative;
  z-index: 2; /* 确保文本在顶部 */
}

.carousel-buttons {
  position: absolute;
  bottom: 20px;
  width: 92%;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  z-index: 1; /* 确保按钮在文本之下 */
  pointer-events: none; /* 防止按钮阻挡点击事件 */
}

.carousel-button1,
.carousel-button2 {
  background: transparent;
  color: #fff;
  border: none;
  padding: 10px 1px;
  cursor: pointer;
  font-size: 24px;
  transition: color 0.3s;
  pointer-events: auto; /* 恢复按钮自身的点击事件 */
  &:hover {
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (min-width: 1025px) {
  .carousel-overlay {
    top: 55%;
    transform: translate(-50%, -50%);
    width: 57%;
    max-width: 600px;
  }
}

@media (max-width: 1024px) {
  .carousel-overlay {
    top: 54%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 400px;
  }
}

@media (max-width: 768px) {
  .carousel-overlay {
    top: 55%;
    transform: translate(-50%, -50%);
    width: 90%;
    max-width: 300px;
  }
}

@media (max-width: 480px) {
  .carousel-overlay {
    top: 55%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 200px;
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;