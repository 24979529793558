
.approval-container {
  padding: 20px;
}

.uploaded-image {
  max-width: 100%;
  margin-top: 10px;
}

.approval-container {
  padding: 20px;
}

.uploaded-image {
  max-width: 100%;
  margin-top: 10px;
}

.approval-button {
  margin-top: 20px;
  width: 120px;
  height: 40px;
  font-size: 16px;
  border-radius: 20px;
}

.approval-button.primary {
  background-color: #1989fa;
  color: #fff;
}

.approval-button.default {
  background-color: #fff;
  color: #1989fa;
  border: 1px solid #1989fa;
}

.approval-button[disabled] {
  opacity: 0.5;
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;