
.motor-drive-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.responsive-image {
  max-width: 100%;
  position: relative;
}

.image-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

// .overlay-container {
//   position: absolute;
//   top: 206px;
//   left: 0;
//   width: 100%;
//   height: calc(100% - 245px);
//   overflow-y: auto; // 允许垂直滚动
//   display: grid;
//   grid-template-columns: repeat(2, 1fr); // 每行两个元素
//   grid-gap: 15px;
//   padding: 10px;
//   box-sizing: border-box; // 确保 padding 不影响高度
// }
.overlay-container {
  position: absolute;
  top: 210px;
  left: 0;
  width: 100%;
  height: calc(85% - 130px);
  overflow-y: auto; // 允许垂直滚动
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 每行两个元素
  grid-gap: 15px;
  padding: 10px;
  box-sizing: border-box; // 确保 padding 不影响高度
}

.date-price {
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  background-color: rgba(64, 73, 90, 0.8);
  color: #fff;
  width: 100%; // 确保与父元素宽度相同
}

.date-price span:first-child,
.date-price span:nth-child(1) {
  display: block;
}

.motor-drive-pic {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.carousel-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 4px;
  width: 100%; // 占据整个容器宽度
  height: 130px;
  margin-bottom: 26px; // 底部间距
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;