
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.weui-cells__group_form {
  display: flex;
  flex-direction: column; /* 在小屏幕上垂直排列 */

  @media only screen and (min-width: 768px) {
    flex-direction: row; /* 在大屏幕上水平排列 */
  }
}

.weui-cell {
  width: 100%; /* 设置宽度为父容器的100% */

  @media only screen and (min-width: 768px) {
    width: 50%; /* 在大屏幕上设置宽度为父容器的50% */
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;