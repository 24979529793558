
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body,
button,
input,
select,
table,
textarea {
  font-family: Microsoft YaHei, Tahoma, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  color: #2c3e50;
}
z div {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.main {
  height: 100vh;
  background-color: #f4f4f4;
}
// 路由出口动画
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s;
}
.fade-enter,
.fade-leave-to {
  transform: translateY(20px);
  opacity: 0;
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;