
/* 设置整个表单容器的样式 */
.form-container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #f5f5f5;
  height: 1000px;
  border-radius: 8px;
  padding-top: 50px;
}

/* 设置每个表单项的样式 */
.form-item {
  width: 100%;
  margin-bottom: 16px;
}

/* 设置日历输入框的样式 */
.calendar-input {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

/* 设置文件上传输入框的样式 */
.uploader-input {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
}

/* 设置留言文本框的样式 */
.message-textarea {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #ccc;
  resize: vertical; /* 允许垂直调整大小 */
}

/* 设置提交按钮的样式 */
.submit-button {
  width: 100%;
  margin-top: 16px;
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;