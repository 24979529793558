
html,
body {
  height: 100%;
  margin: 0;
}
.user-list ul {
  list-style-type: none;
  padding: 0;
}

.user-list li {
  padding: 8px;
  border-bottom: 1px solid #ccc;
}
.uploading-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
}

.image-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;
  height: 200px;
  overflow: hidden;
  margin-bottom: 20px;
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.search-box {
  width: 100%;
  box-sizing: border-box;
}

/* 图片列表样式 */
.image-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 0.7;
  overflow-y: auto;
  gap: 5px; /* 减小行与行之间的间距 */
}

.image-item {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.image-item img {
  width: 100%;
  height: 62%;
  object-fit: fill; /* 确保图片完全显示且不失真 */
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
/* 下拉列表样式 */
.dropdown-list {
  margin-top: 10px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  list-style-type: none;
  padding: 0;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
/* 针对小屏幕设备（如手机） */
@media (max-width: 480px) {
  .image-wrapper {
    width: 100%; /* 在小屏幕上填充整个宽度 */
    max-width: none; /* 禁用最大宽度 */
  }
  .search-form {
    width: 90%; /* 搜索框占据90%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }
  .search-box {
    font-size: 14px; /* 减小字体大小 */
  }
}

/* 针对平板设备 */
@media (min-width: 481px) and (max-width: 768px) {
  .image-wrapper {
    width: 85%; /* 中等宽度 */
  }

  .search-form {
    width: 80%; /* 搜索框占据80%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 16px; /* 标准字体大小 */
  }
}

/* 针对小型笔记本电脑和平板 */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-wrapper {
    width: 80%; /* 稍窄一点 */
  }

  .search-form {
    width: 70%; /* 搜索框占据70%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 18px; /* 更大的字体大小 */
  }
}

/* 针对桌面显示器 */
@media (min-width: 1025px) {
  .image-wrapper {
    width: 100%; /* 较窄宽度 */
  }

  .search-form {
    width: 60%; /* 搜索框占据60%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 20px; /* 更大的字体大小 */
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;