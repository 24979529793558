
.login {
  margin: 79px auto 40px;
  text-align: center;
  height: 100px;
  line-height: 60px;
  img {
    height: 100px;
  }
}
.login-info {
  .from-group {
    line-height: 55px;
    margin: 0 25px;
    position: relative;
    input {
      height: 15px;
      line-height: 15px;
      padding: 20px 0;
      width: 100%;
      font-size: 15px;
      color: #191a1b;
      border: 0;
      outline-width: 0;
    }
    .disable {
      position: absolute;
      right: 0px;
      top: 13px;
      color: #bdc0c5 !important;
      line-height: 30px;
      width: 90px;
      text-align: right;
      float: right;
      border-radius: 3px;
      cursor: pointer;
      font-size: 13px;
    }
    .clear-btn {
      position: absolute;
      top: 20px;
      right: 90px;
      width: 18px;
      height: 18px;
    }
  }
  .from-group:after {
    content: " ";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ededed;
    transform-origin: 0 0;
    border-bottom: 1px solid #ededed;
    transform: scaleY(0.5);
  }
  .submit {
    margin-top: 70px;
    line-height: 45px;
    font-size: 16px;
    margin: 70px 25px 0;
    border-radius: 3px;
    text-align: center;
    background-color: #ff5f16;
    height: 44px;
    color: #fff;
    border: none;
    span {
      line-height: 45px;
      font-size: 17px;
      margin: 70px 25px 0;
      border-radius: 3px;
      text-align: center;
      height: 44px;
      color: #ff8c58;
      border: none;
    }
  }
  .can-not-get {
    text-align: right;
    margin: 15px 25px 0;
    color: #797d82;
    font-size: 13px;
    span {
      text-align: right;
      color: #797d82;
      font-size: 13px;
    }
  }
  .small-checkbox {
    transform: scale(0.9);
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;