
html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}
@keyframes breath {
  0%, 100% {
    background-color: transparent;
  }
  50% {
    background-color: rgba(189, 21, 21, 0.3); /* 黑色半透明 */
    // background-color: rgba(255, 255, 255, 0.3);
  }
}
// .highlighted {
//   background-color: black;
//   transition: background-color 0.5s;
// }
.highlighted {
  animation-name: breath;
  animation-duration: 3s; /* 动画持续时间 */
  animation-iteration-count: infinite; /* 无限循环 */
  animation-timing-function: ease-in-out; /* 加速减速 */
  transition: background-color 0.5s;
}

.uploading-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
.item-list-overlay ul {
  max-height: 600px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.image-wrapper {
  position: relative;
  width: 90%;
  max-width: 1200px;

  overflow: hidden;
  margin-bottom: 10px;
}

.image-wrapper img {
  width: 100%;
  object-fit: cover;
  display: block;
}

.search-box {
  width: 100%;
  box-sizing: border-box;
}
/* 下拉列表样式 */
.dropdown-list {
  margin-top: 10px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  background-color: white;
  list-style-type: none;
  padding: 0;
}

.dropdown-item {
  padding: 8px 16px;
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
.image-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
}
.sample-image {
  width: 100%;
  height: auto; /* 确保图片按比例缩放 */
  display: block;
}

.item-list-overlay {
  position: absolute;
  top: -12px;
  left: 0;
  right: 0; /* 半透明背景色 */
  padding: 20px;
  border-radius: 0 0 8px 8px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }

  span {
    flex-grow: 1;
    color: white;
    margin-right: 20px;
  }

  button {
    background-color: transparent;
    border: none;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
    transition: color 0.3s;

    &:hover {
      color: #d4edda;
    }

    &.preview-btn {
      margin-right: 10px;
    }
  }
}
/* 针对小屏幕设备（如手机） */
@media (max-width: 480px) {
  .image-wrapper {
    width: 100%; /* 在小屏幕上填充整个宽度 */
    max-width: none; /* 禁用最大宽度 */
  }
  .search-form {
    width: 90%; /* 搜索框占据90%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }
  .search-box {
    font-size: 14px; /* 减小字体大小 */
  }
  .sample-image {
    width: 100%;
    max-width: 100%;
    height: 600px;
    object-fit: cover;
    margin: 0;
    padding: 0;
  }
}

/* 针对平板设备 */
@media (min-width: 481px) and (max-width: 768px) {
  .image-wrapper {
    width: 85%; /* 中等宽度 */
  }

  .search-form {
    width: 80%; /* 搜索框占据80%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 16px; /* 标准字体大小 */
  }
}

/* 针对小型笔记本电脑和平板 */
@media (min-width: 769px) and (max-width: 1024px) {
  .image-wrapper {
    width: 80%; /* 稍窄一点 */
  }

  .search-form {
    width: 70%; /* 搜索框占据70%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 18px; /* 更大的字体大小 */
  }
}

/* 针对桌面显示器 */
@media (min-width: 1025px) {
  .image-wrapper {
    width: 100%; /* 较窄宽度 */
  }

  .search-form {
    width: 60%; /* 搜索框占据60%的宽度 */
    padding: 0 10px; /* 添加一些内边距 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 30%);
    z-index: 10;
  }

  .search-box {
    font-size: 20px; /* 更大的字体大小 */
  }
}

@dropdown-menu-box-shadow: none;@tabs-line-height: 49px;